








































































































import { Component, Prop } from 'vue-property-decorator'

import { CartItemMixin } from '../../../../shared/mixins/cartItem.mixin'

import { CustomQuantityPicker } from './../partials'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<RichCartItem>({
  name: 'RichCartItem',
  components: { CustomQuantityPicker },
  beforeDestroy (): void {
    this.notify('isLoading', false)
  }
})
export class RichCartItem extends CartItemMixin {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly isCartAvailableForLocale!: boolean
}

export default RichCartItem
